// src/_API/_uriConstructor.js

export const uriConstructor = (section, idData = null, params = {}) => {
    console.log("Construyendo URI para la sección:", section, "con idData:", idData, "y params:", params);
  
    if (!section) {
      throw new Error("Section is undefined or invalid. Please provide a valid section.");
    }
  
    // Reemplazamos puntos por doble-guión-bajo
    const formattedSection = section.replace(/\./g, '__');
  
    // Definición de endpoints
    const URI_SELECTED = {
      // --------------------------------------------
      // CLAIMS (Reclamos)
      // --------------------------------------------
      "CLAIMS__CLAIM_CREATE":           ()    => `/reclamos/`,
      "CLAIMS__CLAIM_UPDATE":           (id) => `/reclamos/${id}/`,
      "CLAIMS__CLAIM_DELETE":           (id) => `/reclamos/${id}/`,
      "CLAIMS__CLAIM_GET_LIST":         ()    => `/reclamos/`,
      "CLAIMS__CLAIM_GET_DETAIL":       (id) => `/reclamos/${id}/`,
      "CLAIMS__CLAIM_UPDATE_STATUS":    (id) => `/reclamos/${id}/update-status/`,
      "CLAIMS__ASIGNACIONES_PROVEEDORES_GET_LIST": () => `/reclamos/asignaciones-proveedores/`,
      "CLAIMS__ARCHIVE":               (id) => `/reclamos/${id}/archivar/`,
      "CLAIMS__ARCHIVED_LIST":         ()    => `/reclamos/archivados/`,
      // <-- NUEVAS RUTAS ↓↓↓
      "CLAIMS__ASIGNACION_GET_DETAIL": (id) => `/reclamos/asignaciones-proveedores/${id}/`,
      "CLAIMS__ASIGNACION_ADD_OBS":    (id) => `/reclamos/asignaciones-proveedores/${id}/observaciones/`,
  
    // Auth
    "USERS__AUTH_LOGIN_USER":         () => `/user/login/`,
    "USERS__AUTH_REFRESH_TOKEN":      () => `/user/login/refresh/`,
    "USERS__AUTH_LOGOUT_USER":        () => `/user/logout/`,

    // Registro
    "USERS__AUTH_REGISTER_USER":      () => `/user/register/`,  // crea usuario principal (exige CUIT)

    // Crear usuario asociado
    "USERS__ASSOCIATED_CREATE":       () => `/user/users/associated-create/`,

    // Password Reset
    "USERS__AUTH_RECOVERY_USER":      () => `/user/password-reset/`,
    "USERS__RESET_PASSWORD_CONFIRM":  ({ uid, token }) => `/user/password-reset-confirm/${uid}/${token}/`,
    "USERS__RESET_PASSWORD": () => `/user/password-reset/`,

    // Usuarios => SOLO get/put/delete en /user/users/:id
    "USERS__LIST_ALL_USERS":          () => `/user/users/`,         // GET
    "USERS__GET_DETAIL_USER":         (id) => `/user/users/${id}/`, // GET
    "USERS__UPDATE_USER":             (id) => `/user/users/${id}/`, // PUT/PATCH
    "USERS__DELETE_USER":             (id) => `/user/users/${id}/`, // DELETE

    // Módulos
    "USERS__MODULES_GET_LIST":        () => `/user/modules/`,       // GET
    // Grupos
    "USERS__GROUPS_GET_LIST":         () => `/user/groups/`,
    "USERS__GROUPS_GET_DETAIL":       (id) => `/user/groups/${id}/`,
    "USERS__GROUPS_CREATE":           () => `/user/groups/`,
    "USERS__GROUPS_UPDATE":           (id) => `/user/groups/${id}/`,
    "USERS__GROUPS_DELETE":           (id) => `/user/groups/${id}/`,
    
  
      // --------------------------------------------
      // EMAIL
      // --------------------------------------------
      "EMAIL__SENDGRID_CONFIG_GET":    () => `/sendgrid-config/`,
      "EMAIL__SENDGRID_CONFIG_UPDATE": () => `/sendgrid-config/`,
      "EMAIL__SENDGRID_WEBHOOK":       () => `/sendgrid-webhook/`,
      "EMAIL__ATTACHMENT_QR_GET":      (id) => `/attachment-qr/${id}/`,
  
      // --------------------------------------------
      // CONSORTIUMS (Consorcios)
      // --------------------------------------------
      "CONSORTIUMS__BANK_CREATE":      ()    => `/consorcios/bancos/`,
      "CONSORTIUMS__BANK_UPDATE":      (id) => `/bancos/${id}/`,
      "CONSORTIUMS__BANK_DELETE":      (id) => `/bancos/${id}/`,
      "CONSORTIUMS__BANK_GET_LIST":    ()    => `/consorcios/bancos/`,
      "CONSORTIUMS__BANK_GET_DETAIL":  (id) => `/bancos/${id}/`,
  
      "CONSORTIUMS__CONSORTIUM_CREATE":     ()    => `/consorcios/consorcios/`,
      "CONSORTIUMS__CONSORTIUM_UPDATE":     (id) => `/consorcios/consorcios/${id}/`,
      "CONSORTIUMS__CONSORTIUM_DELETE":     (id) => `/consorcios/consorcios/${id}/`,
      "CONSORTIUMS__CONSORTIUM_GET_LIST":   ()    => `/consorcios/consorcios`, // ESTE SÍ es /consorcios/consorcios
      "CONSORTIUMS__CONSORTIUM_GET_DETAIL": (id) => `/consorcios/consorcios/${id}/`,
      
      "CONSORTIUMS__CONSORTIUM_BULK_IMPORT": () => "/consorcios/importar/",  
      "CONSORTIUMS__PERIOD_CREATE":        ()    => `/periodos/`,
      "CONSORTIUMS__PERIOD_GET_LIST":      ()    => `/periodos/listar/`,
      "CONSORTIUMS__PERIOD_GET_DETAIL":    (pk)  => `/periodos/${pk}/`,
      "CONSORTIUMS__PERIOD_UPDATE":        (pk)  => `/periodos/${pk}/cerrar/`,
      "CONSORTIUMS__PERIOD_DELETE":        (pk)  => `/periodos/${pk}/delete/`,
      "CONSORTIUMS__PERIOD_VERIFY":        ({ consorcioId, fecha }) => 
                                            `/consorcios/${consorcioId}/verificar_periodo/?fecha=${fecha}`,
      "CONSORTIUMS__PERIOD_VERIFY_MULTIPLE": (consorcioId) => 
                                            `/consorcios/${consorcioId}/verificar_periodos/`,
  
      "CONSORTIUMS__CASH_CREATE":         ()    => `/cajas/`,
      "CONSORTIUMS__CASH_UPDATE":         (id) => `/cajas/${id}/`,
      "CONSORTIUMS__CASH_DELETE":         (id) => `/cajas/${id}/`,
      "CONSORTIUMS__CASH_GET_LIST":       ()    => `/cajas/`,
      "CONSORTIUMS__CASH_GET_DETAIL":     (id) => `/cajas/${id}/`,
  
      "CONSORTIUMS__OWN_CREATE": () => `/propietarios/`,
      "CONSORTIUMS__OWN_UPDATE": (id) => `/propietarios/${id}/`,
      "CONSORTIUMS__OWN_DELETE": (id) => `/propietarios/${id}/`,
      "CONSORTIUMS__OWN_GET_DETAIL": (id) => `/propietarios/${id}/`,
      "CONSORTIUMS__OWN_GET_LIST": () => `/propietarios/`,
      "CONSORTIUMS__OWN_GET_LIST_BY_CONSORTIUM": (consorcioId) => `/consorcios/${consorcioId}/propietarios/`,
      "CONSORTIUMS__BANKS_BY_CONSORCIO": (consorcioId) => `/bancos-por-consorcio/?consorcio_id=${consorcioId}`,
  
      // --------------------------------------------
      // COLLECTIONS (Cobranzas)
      // --------------------------------------------
      "COLLECTIONS__COLLECTION_CREATE":     ()    => `/cobranzas/`,
      "COLLECTIONS__COLLECTION_UPDATE":     (id) => `/cobranzas/${id}/`,
      "COLLECTIONS__COLLECTION_DELETE":     (id) => `/cobranzas/${id}/`,
      "COLLECTIONS__COLLECTION_GET_LIST":   ()    => `/cobranzas/`,
      "COLLECTIONS__COLLECTION_GET_DETAIL": (id) => `/cobranzas/${id}/`,
  
      // --------------------------------------------
      // MOVEMENTS - CONSORCIOS
      // --------------------------------------------
      "CONSORTIUMS__MOVEMENT_CREATE":       ()    => `/consorcios/movimientos/`,
      "CONSORTIUMS__MOVEMENT_UPDATE":       (id) => `/consorcios/movimientos/${id}/`,
      "CONSORTIUMS__MOVEMENT_DELETE":       (id) => `/consorcios/movimientos/${id}/`,
      "CONSORTIUMS__MOVEMENT_GET_LIST":     ()    => `/consorcios/movimientos/`,
      "CONSORTIUMS__MOVEMENT_GET_DETAIL":   (id) => `/consorcios/movimientos/${id}/`,
  
      // --------------------------------------------
      // INVESTMENTS
      // --------------------------------------------
      "INVESTMENTS__INVESTMENT_CREATE":     () => `/inversiones/`,
      "INVESTMENTS__INVESTMENT_UPDATE":     (id) => `/inversiones/${id}/`,
      "INVESTMENTS__INVESTMENT_DELETE":     (id) => `/inversiones/${id}/`,
      
      "INVESTMENTS__INVESTMENT_GET_DETAIL": (id) => `/inversiones/${id}/`,
      "INVESTMENTS__INVESTMENT_CLOSE": (id) => `/inversiones/${id}/cerrar/`,
      "INVESTMENTS__INVESTMENT_GET_LIST": () => "/inversiones/",
      "INVESTMENTS__ACCOUNT_GET_LIST_BY_CONSORCIO": (consorcioId) => `/cuentas-inversion/?consorcio_id=${consorcioId}`,
      

      // Ejemplo de endpoints
      "FOREIGNCURRENCY__FX_CREATE":     () => `/monedas-extranjeras/`,
      "FOREIGNCURRENCY__FX_UPDATE":     (id) => `/monedas-extranjeras/${id}/`,
      "FOREIGNCURRENCY__FX_DELETE":     (id) => `/monedas-extranjeras/${id}/`,
      "FOREIGNCURRENCY__FX_GET_LIST":   () => `/monedas-extranjeras/`,
      "FOREIGNCURRENCY__FX_GET_DETAIL": (id) => `/monedas-extranjeras/${id}/`,
      "FOREIGNCURRENCY__BANKS_BY_CONSORCIO": (consorcioId) => `/bancos-por-consorcio/?consorcio_id=${consorcioId}`,
      
      // --------------------------------------------
      // RECONCILIATIONS
      // --------------------------------------------
      "RECONCILIATIONS__CREATE":                () => `/conciliaciones/`,
      "RECONCILIATIONS__UPDATE":                (id) => `/conciliaciones/${id}/`,
      "RECONCILIATIONS__DELETE":                (id) => `/conciliaciones/${id}/`,
      "RECONCILIATIONS__DETAIL":                (id) => `/conciliaciones/${id}/`,
      "RECONCILIATIONS__IMPORT_BANK_STATEMENT": () => `/conciliaciones/importar/`,

      // NUEVAS RUTAS (BANCOS VS. ARCHIVO)
      "RECONCILIATIONS__GET_BANK_MOVEMENTS":    (id) => `/conciliaciones/${id}/movimientos-banco/`,
      "RECONCILIATIONS__GET_FILE_MOVEMENTS":    (id) => `/conciliaciones/${id}/movimientos-archivo/`,

      "RECONCILIATIONS__UPDATE_STATUS":         (id) => `/conciliaciones/${id}/actualizar_estado/`,
      "RECONCILIATIONS__CONCILIATE_BATCH":      (id) => `/conciliaciones/${id}/conciliar_lote/`,
      "RECONCILIATIONS__CONCILIATE_MOVEMENT":   () => `/conciliaciones/conciliar_movimiento/`,
      "RECONCILIATIONS__LIST":                  () => `/conciliaciones/`,
      "RECONCILIATIONS__GET_DATA":              (id) => `/conciliaciones/${id}/datos/`,
      "RECONCILIATIONS__CONFIRM":               (id) => `/conciliaciones/${id}/confirmar/`,
      "RECONCILIATIONS__DELETE_CONCILIATION":   (id) => `/conciliaciones/${id}/eliminar/`,

      "CONSORTIUMS__CURRENT_ACCOUNT_CASH_LIST": () => `/consorcios/cuenta_corriente_caja/`,
      "CONSORTIUMS__BANK_CHECKING_ACCOUNT_DETAIL": (id) => `/cuenta-corriente-banco/${id}/detalle/`,

      "RECONCILIATIONS__CLOSE":                 (id) => `/conciliaciones/${id}/cerrar/`,
      "RECONCILIATIONS__CONFIRM_BANK_CHARGES":  (id) => `/conciliaciones/${id}/confirmar_gastos_bancarios/`,
      "CONSORTIUMS__CURRENT_ACCOUNT_CASH_LIST": (id) => `/cuenta_corriente_caja/by-caja/${id}/`,
      "RECONCILIATIONS__GET_ACCOUNT_BALANCE": (id) => `/conciliaciones/${id}/saldo_cuenta/`,


      // --------------------------------------------
      // MOVEMENTS - PAYMENTS
      // --------------------------------------------
      "MOVEMENTS__PAYMENT_CREATE":      ()    => `/pagos/`,
      "MOVEMENTS__PAYMENT_UPDATE":      (id) => `/pagos/${id}/`,
      "MOVEMENTS__PAYMENT_DELETE":      (id) => `/pagos/${id}/`,
      "MOVEMENTS__PAYMENT_GET_LIST":    ()    => `/pagos-efectuados/`,
      "MOVEMENTS__PAYMENT_GET_DETAIL":  (id) => `/pagos/${id}/`,
  
      // --------------------------------------------
      // LIQUIDATIONS
      // --------------------------------------------
      "LIQUIDATIONS__GENERATE":                 () => `/liquidacion/generar/`,
      "LIQUIDATIONS__PREVIEW":                  () => `/liquidacion/previsualizar/`,
      "LIQUIDATIONS__EXPENSES_BY_UF":           (id) => `/liquidacion/expensas-por-uf/${id}/`,
      "LIQUIDATIONS__RECENT":                   () => `/liquidaciones-recientes/`,
      "LIQUIDATIONS__DETAIL":                   (id) => `/liquidacion/detalle/${id}/`,
      "LIQUIDATIONS__DELETE":                   (id) => `/liquidacion/eliminar/${id}/`,
      "LIQUIDATIONS__EDIT":                     (id) => `/liquidacion/editar/${id}/`,
  
      // Grupo Porcentual
      "PERCENTAGE_GROUPS__LIST":     () => `/grupos-porcentuales/`,
      "PERCENTAGE_GROUPS__DETAIL":   (id) => `/grupos-porcentuales/${id}/`,
      "PERCENTAGE_GROUPS__CREATE":   () => `/grupos-porcentuales/`,
      "PERCENTAGE_GROUPS__UPDATE":   (id) => `/grupos-porcentuales/${id}/`,
      "PERCENTAGE_GROUPS__DELETE":   (id) => `/grupos-porcentuales/${id}/`,
  
      // Porcentaje Unidad
      "UNIT_PERCENTAGES__LIST":       () => `/porcentajes-unidad/`,
      "UNIT_PERCENTAGES__DETAIL":     (id) => `/porcentajes-unidad/${id}/`,
      "UNIT_PERCENTAGES__CREATE":     () => `/porcentajes-unidad/`,
      "UNIT_PERCENTAGES__UPDATE":     (id) => `/porcentajes-unidad/${id}/`,
      "UNIT_PERCENTAGES__DELETE":     (id) => `/porcentajes-unidad/${id}/`,
  
      // Grupo
      "GROUPS__LIST":                 () => `/grupos/`,
      "GROUPS__DETAIL":               (id) => `/grupos/${id}/`,
      "GROUPS__CREATE":               () => `/grupos/`,
      "GROUPS__UPDATE":               (id) => `/grupos/${id}/`,
      "GROUPS__DELETE":               (id) => `/grupos/${id}/`,
  
      // Concepto Expensa
      "EXPENSE_CONCEPTS__LIST":       () => `/conceptos-expensa/`,
      "EXPENSE_CONCEPTS__DETAIL":     (id) => `/conceptos-expensa/${id}/`,
      "EXPENSE_CONCEPTS__CREATE":     () => `/conceptos-expensa/`,
      "EXPENSE_CONCEPTS__UPDATE":     (id) => `/conceptos-expensa/${id}/`,
      "EXPENSE_CONCEPTS__DELETE":     (id) => `/conceptos-expensa/${id}/`,
  
      "LIQUIDATIONS__CUENTAS_CORRIENTES_PROP_GET_LIST":   () => `/cuentascorrientes_propietarios/`,
      "LIQUIDATIONS__CUENTAS_CORRIENTES_PROP_CREATE":     () => `/cuentascorrientes_propietarios/`,
      "LIQUIDATIONS__CUENTAS_CORRIENTES_PROP_UPDATE":     (id) => `/cuentascorrientes_propietarios/${id}/`,
      "LIQUIDATIONS__CUENTAS_CORRIENTES_PROP_DELETE":     (id) => `/cuentascorrientes_propietarios/${id}/`,
      "LIQUIDATIONS__CUENTAS_CORRIENTES_PROP_GET_DETAIL": (id) => `/cuentascorrientes_propietarios/${id}/`,
  
      "RESUMEN__CONSORCIO_GET_LIST": (id) => `/resumen/${id}/`,
      "RESUMEN__CONSORCIO_GET_PDF":  (id) => `/resumen/${id}/pdf/`,
  
      // MOVEMENTS - EXPENSES SECTOR
      "MOVEMENTS__EXPENSES_SECTOR_CREATE":     () => `/rubros/`,
      "MOVEMENTS__EXPENSES_SECTOR_UPDATE":     (id) => `/rubros/${id}/`,
      "MOVEMENTS__EXPENSES_SECTOR_DELETE":     (id) => `/rubros/${id}/`,
      "MOVEMENTS__EXPENSES_SECTOR_GET_LIST":   () => `/rubros/`,
      "MOVEMENTS__EXPENSES_SECTOR_GET_DETAIL": (id) => `/rubros/${id}/`,
  
      // MOVEMENTS - EXPENSES ACCOUNT
      "MOVEMENTS__EXPENSES_ACCOUNT_CREATE":    () => `/cuentas/`,
      "MOVEMENTS__EXPENSES_ACCOUNT_UPDATE":    (id) => `/cuentas/${id}/`,
      "MOVEMENTS__EXPENSES_ACCOUNT_DELETE":    (id) => `/cuentas/${id}/`,
      "MOVEMENTS__EXPENSES_ACCOUNT_GET_LIST":  () => `/cuentas/`,
      "MOVEMENTS__EXPENSES_ACCOUNT_GET_DETAIL":(id) => `/cuentas/${id}/`,
  
      // MOVEMENTS - EXPENSES PROVIDER
      "MOVEMENTS__EXPENSES_PROVIDER_CREATE":     () => `/proveedores/`,
      "MOVEMENTS__EXPENSES_PROVIDER_UPDATE":     (id) => `/proveedores/${id}/`,
      "MOVEMENTS__EXPENSES_PROVIDER_DELETE":     (id) => `/proveedores/${id}/`,
      "MOVEMENTS__EXPENSES_PROVIDER_GET_LIST":   () => `/proveedores/`,
      "MOVEMENTS__EXPENSES_PROVIDER_GET_DETAIL": (id) => `/proveedores/${id}/`,
  
      // MOVEMENTS - EXPENSES EXPENSE
      "MOVEMENTS__EXPENSES_EXPENSE_CREATE":      () => `/gastos/`,
      "MOVEMENTS__EXPENSES_EXPENSE_UPDATE":      (id) => `/gastos/${id}/`,
      "MOVEMENTS__EXPENSES_EXPENSE_DELETE":      (id) => `/gastos/${id}/`,
      "MOVEMENTS__EXPENSES_EXPENSE_GET_LIST":    () => `/gastos/`,
      "MOVEMENTS__EXPENSES_EXPENSE_GET_DETAIL":  (id) => `/gastos/${id}/`,
      "MOVEMENTS__EXPENSES_EXPENSE_CONFIRM":     (id) => `/gastos/${id}/confirmar/`,
  
      // MOVEMENTS - EXPENSES CTA CTE PROVIDER
      "MOVEMENTS__EXPENSES_CTA_CTE_PROVIDER_GET_LIST": (id) => `/detalle_cuenta_corriente/${id}/`,
      "EXPENSES__REPORT_GET":               () => `/gastos/reporte/`,
      "EXPENSES__FACTURAS_NO_PAGADAS_GET":   () => `/gastos/facturas_no_pagadas/`,
      "SUPPLIERS__SUPPLIER_GET_LIST":        () => `/proveedores/`,
      "MOVEMENTS__PAYMENTS_EFFECTUATED_LIST":() => `/pagos-efectuados/`, // new
  
      // --------------------------------------------
      // AMENITIES
      // --------------------------------------------
      "CONSORTIUMS__AMENITI_CREATE":        () => `/ammenitis/`,
      "CONSORTIUMS__AMENITI_UPDATE":        (id) => `/ammenitis/${id}/`,
      "CONSORTIUMS__AMENITI_DELETE":        (id) => `/ammenitis/${id}/`,
      "CONSORTIUMS__AMENITI_GET_LIST":      () => `/ammenitis/`,
      "CONSORTIUMS__AMENITI_GET_DETAIL":    (id) => `/ammenitis/${id}/`,
  
      // Disponibilidad Amenity
      "CONSORTIUMS__AMENITY_AVAILABILITY_CREATE":             () => `/amenity-availability/`,
      "CONSORTIUMS__AMENITY_AVAILABILITY_GET_LIST_BY_AMENITI":(amenityId) => `/amenity-availability/?amenity=${amenityId}`,
      "CONSORTIUMS__AMENITY_AVAILABILITY_DELETE":             (id) => `/amenity-availability/${id}/`,
      "CONSORTIUMS__AMENITY_AVAILABLE_SLOTS":                 () => `/amenity-available-slots/`,
  
      // Reservas
      "CONSORTIUMS__RESERVA_CREATE":    () => `/reservas/`,
      "CONSORTIUMS__RESERVA_UPDATE":    (id) => `/reservas/${id}/`,
      "CONSORTIUMS__RESERVA_DELETE":    (id) => `/reservas/${id}/`,
      "CONSORTIUMS__RESERVA_GET_LIST":  () => `/reservas/`,
  
      // WhatsApp
      "WHATSAPP__HANDLE_INCOMING":       () => `/api/whatsapp/handle-incoming/`,
      "WHATSAPP__STATUS_CALLBACK":       () => `/api/whatsapp/status/`,
      "WHATSAPP__CUSTOM_MESSAGE_SEND":   () => `/api/whatsapp/custom-message/`,
      "WHATSAPP__TEMPLATE_MESSAGE_SEND": () => `/api/whatsapp/template-message/`,
  
      // IA (Chat)
      "AI__CHAT":        () => `/api/ai/chat/`,
      "AI__CHAT_STREAM": () => `/api/ai/chat/stream/`,
  
      // --------------------------------------------
      // MOVIMIENTOS FINANCIEROS (NUEVA APP)
      // --------------------------------------------
      "FINANCIAL_MOVEMENTS__GET_LIST":    ()    => `/movimientos-financieros/`,
      "FINANCIAL_MOVEMENTS__CREATE":      ()    => `/movimientos-financieros/`,
      "FINANCIAL_MOVEMENTS__GET_DETAIL":  (id) => `/movimientos-financieros/${id}/`,
      "FINANCIAL_MOVEMENTS__UPDATE":      (id) => `/movimientos-financieros/${id}/`,
      "FINANCIAL_MOVEMENTS__DELETE":      (id) => `/movimientos-financieros/${id}/`,
      "FINANCIAL_MOVEMENTS__CONFIRM":     (id) => `/movimientos-financieros/${id}/confirmar/`,
      "FINANCIAL_MOVEMENTS__ANULAR":      (id) => `/movimientos-financieros/${id}/anular/`,

  
      // --------------------------------------------
      // REPORTES GENERALES
      // --------------------------------------------
      "REPORTS__GENERAL_GET_LIST":   () => `/informes-generales/`,
      "REPORTS__GENERAL_GET_DETAIL": (id) => `/informes-generales/${id}/`,
      "REPORTS__GENERAL_CREATE":     () => `/informes-generales/`,
      "REPORTS__GENERAL_UPDATE":     (id) => `/informes-generales/${id}/`,
      "REPORTS__GENERAL_DELETE":     (id) => `/informes-generales/${id}/`,
      "REPORTS__GENERAL_RESEND":     (id) => `/informes-generales/${id}/reenviar/`,
  
      // --------------------------------------------
      // CONVENIOS
      // --------------------------------------------
      "CONVENIOS__GET_LIST": () => `/convenios/`,
      "CONVENIOS__CREATE":   () => `/convenios/`,
      "CONVENIOS__UPDATE":   (id) => `/convenios/${id}/`,
      "CONVENIOS__DELETE":   (id) => `/convenios/${id}/`,
  
      // Encargados Consorcio
      "ENCARGADOS__GET_LIST":              () => `/encargados-consorcio/`,
      "ENCARGADOS__CREATE":                () => `/encargados-consorcio/`,
      "ENCARGADOS__UPDATE":                (id) => `/encargados-consorcio/${id}/`,
      "ENCARGADOS__DELETE":                (id) => `/encargados-consorcio/${id}/`,
      "ENCARGADOS__GET_LIST_BY_CONSORCIO": (consorcioId) => `/encargados-consorcio/por_consorcio/?consorcio_id=${consorcioId}`,
  
      // Conceptos Remunerativos
      "CONCEPTOS_REMUNERATIVOS__GET_LIST":  () => `/conceptos-remunerativos/`,
      "CONCEPTOS_REMUNERATIVOS__CREATE":    () => `/conceptos-remunerativos/`,
      "CONCEPTOS_REMUNERATIVOS__UPDATE":    (id) => `/conceptos-remunerativos/${id}/`,
      "CONCEPTOS_REMUNERATIVOS__DELETE":    (id) => `/conceptos-remunerativos/${id}/`,
  
      // Conceptos No Remunerativos
      "CONCEPTOS_NO_REMUNERATIVOS__GET_LIST":   () => `/conceptos-no-remunerativos/`,
      "CONCEPTOS_NO_REMUNERATIVOS__CREATE":     () => `/conceptos-no-remunerativos/`,
      "CONCEPTOS_NO_REMUNERATIVOS__UPDATE":     (id) => `/conceptos-no-remunerativos/${id}/`,
      "CONCEPTOS_NO_REMUNERATIVOS__DELETE":     (id) => `/conceptos-no-remunerativos/${id}/`,
      "CONCEPTOS_PARA_LIQUIDACION__DISPONIBLES":() => `/conceptos-para-liquidacion/disponibles/`,
  
      // Descuentos Convenio
      "DESCUENTOS_CONVENIO__GET_LIST":  () => `/descuentos-convenio/`,
      "DESCUENTOS_CONVENIO__CREATE":    () => `/descuentos-convenio/`,
      "DESCUENTOS_CONVENIO__UPDATE":    (id) => `/descuentos-convenio/${id}/`,
      "DESCUENTOS_CONVENIO__DELETE":    (id) => `/descuentos-convenio/${id}/`,
  
      // Liquidaciones Sueldo
      "LIQUIDACIONES_SUELDO__GET_LIST": () => `/liquidaciones-sueldo/`,
      "LIQUIDACIONES_SUELDO__CREATE":   () => `/liquidaciones-sueldo/`,
      "LIQUIDACIONES_SUELDO__UPDATE":   (id) => `/liquidaciones-sueldo/${id}/`,
      "LIQUIDACIONES_SUELDO__DELETE":   (id) => `/liquidaciones-sueldo/${id}/`,
      "LIQUIDACIONES_SUELDO__DETAIL":   (id) => `/liquidaciones-sueldo/${id}/`,
      "LIQUIDACIONES_SUELDO__CONFIRMAR_LIQUIDACION": (id) => `/liquidaciones-sueldo/${id}/confirmar_liquidacion/`,
  
      // Novedades Liquidación
      "NOVELTIES__NOVELTY_GET_LIST":   () => `/novedades-liquidacion/`,
      "NOVELTIES__NOVELTY_CREATE":     () => `/novedades-liquidacion/`,
      "NOVELTIES__NOVELTY_UPDATE":     (id) => `/novedades-liquidacion/${id}/`,
      "NOVELTIES__NOVELTY_DELETE":     (id) => `/novedades-liquidacion/${id}/`,
      "NOVELTIES__NOVELTY_GET_DETAIL": (id) => `/novedades-liquidacion/${id}/`,

      "CATEGORIAS_CCT__GET_LIST":  () => `/categorias-cct/`,
      "CATEGORIAS_CCT__CREATE":    () => `/categorias-cct/`,
      "CATEGORIAS_CCT__UPDATE":    (id) => `/categorias-cct/${id}/`,
      "CATEGORIAS_CCT__DELETE":    (id) => `/categorias-cct/${id}/`,
      "CATEGORIAS_CCT__DETAIL":    (id) => `/categorias-cct/${id}/`,

      "ESCALAS_SALARIALES__GET_LIST": () => `/escalas-salariales/`,
      "ESCALAS_SALARIALES__CREATE":   () => `/escalas-salariales/`,
      "ESCALAS_SALARIALES__UPDATE":   (id) => `/escalas-salariales/${id}/`,
      "ESCALAS_SALARIALES__DELETE":   (id) => `/escalas-salariales/${id}/`,
      "ESCALAS_SALARIALES__DETAIL":   (id) => `/escalas-salariales/${id}/`,

  
      // --------------------------------------------
      // ACCESO TERCEROS
      // --------------------------------------------
      "ACCESO_TERCEROS_CREATE":     () => `/acceso_terceros/`,
      "ACCESO_TERCEROS_UPDATE":     (id) => `/acceso_terceros/${id}/`,
      "ACCESO_TERCEROS_DELETE":     (id) => `/acceso_terceros/${id}/`,
      "ACCESO_TERCEROS_GET_LIST":   () => `/acceso_terceros/`,
      "ACCESO_TERCEROS_GET_DETAIL": (id) => `/acceso_terceros/${id}/`,

        // NUEVO Endpoint para listar carpetas
     "FOLDERS__LOCAL_GET_LIST": () => "/local-folders/",
     "API__DELETE_FILE": (filePath) => `/api/delete-file/?file_path=${filePath}`,
     // NUEVO Endpoint público (no requiere login):
     // ...

    "FOLDERS__LOCAL_GET_PUBLIC_LIST": (_, params = {}) => {
      // Toma admin_id de params, por defecto '9'
      const adminId = params.admin_id || '9';
      // Retorna la URL con query param
      return `/local-folders-public/?admin_id=${adminId}`;
    },


    };
  
    if (!URI_SELECTED[formattedSection]) {
      throw new Error(`Endpoint for section ${formattedSection} is not defined.`);
    }
  
    // Retornamos la URL construida:
    return idData !== null
      ? URI_SELECTED[formattedSection](idData)
      : URI_SELECTED[formattedSection](params);
  };