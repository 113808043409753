// src/Components/_UI/_NavigationApp.js

import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Flex,
  VStack,
  HStack,
  IconButton,
  Link as ChakraLink,
  Badge,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorMode,
  useColorModeValue,
  Text,
  Button,
} from '@chakra-ui/react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartPie, faCog, faChartBar, faBullhorn, faHeadset,
  faFileInvoiceDollar, faBook, faList, faBell, faUserCircle,
  faSun, faMoon, faSignOutAlt, faMoneyBillWave, faTable, faEnvelope, faWallet,
  faCloudSun, faClock, faEnvelopeOpen
} from "@fortawesome/free-solid-svg-icons";

import axios from 'axios';
import { UseDataUserContext } from '../../_Hooks/_Context/useDataUserAuthenticated';
import { ThemeContext } from '../../_Hooks/_Context/ThemeContext'; // if you handle theme context
import WeatherComponent from './WeatherComponent';
import { ApiConsume } from "../../_API/ApiConsume";

// -----------------------------------------------------------
// DATA: Items for the sidebar
// -----------------------------------------------------------
const navItems = [
  { title: "Dashboard", link: '/', icon: faChartPie },
  { title: "Configuración", link: "/panel/configuración", icon: faCog },
  { title: "Finanzas", link: "/panel/finanzas", icon: faChartBar },
  { title: "Movimientos", link: "/panel/movimientos", icon: faBullhorn },
  { title: "Servicios", link: "/panel/servicios", icon: faHeadset },
  { title: "Liquidación", link: "/panel/liquidación", icon: faFileInvoiceDollar },
  { title: "Reportes", link: "/panel/reportes", icon: faTable },
  { title: "Cuentas Corrientes", link: "/panel/ctas_ctes", icon: faWallet },
  { title: "Sueldos", link: "/panel/sueldos", icon: faMoneyBillWave },
  { title: "Correo Electronico", link: "/panel/correo", icon: faEnvelope },
  { title: "Manual", link: "/panel/manual-de-procedimiento", icon: faBook },
  { title: "Requerimientos", link: "/panel/requerimientos", icon: faList },
];

// -----------------------------------------------------------
// SIDEBAR COMPONENT
// -----------------------------------------------------------
export const AdminSidebarApp = () => {
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();

  // Theming for sidebar
  const bgSidebar = useColorModeValue("gray.100", "gray.800");
  const colorSidebar = useColorModeValue("gray.700", "gray.200");

  // Expand/Collapse on hover
  const handleMouseEnter = () => setCollapsed(false);
  const handleMouseLeave = () => setCollapsed(true);

  return (
    <Box
      as="nav"
      // The critical part to occupy full height:
      height="100vh"
      // if your parent layout has no other constraints, you could do:
      // minH="100vh" 
      bg={bgSidebar}
      color={colorSidebar}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      // Transition for smooth width changes
      w={collapsed ? "80px" : "200px"}
      transition="width 0.2s ease"
      overflow="hidden"
    >
      <VStack align="stretch" spacing={2} p={1}>
        {/* Example logo or heading */}
        <Box p={3} textAlign="center">
          {!collapsed
            ? <Text fontWeight="bold" fontSize="lg">WAM</Text>
            : <Text fontWeight="bold" fontSize="lg">WAM</Text>
          }
        </Box>

        {/* Nav items */}
        {navItems.map((item) => {
          const isActive = location.pathname === item.link;
          return (
            <ChakraLink
              as={Link}
              to={item.link}
              key={item.link}
              display="flex"
              alignItems="center"
              py={2}
              px={2}
              borderRadius="md"
              bg={isActive ? "blue.600" : "transparent"}
              color={isActive ? "white" : "inherit"}
              _hover={{
                textDecoration: "none",
                bg: isActive ? "blue.700" : useColorModeValue("gray.200", "gray.700"),
              }}
            >
              <Box minW="24px" textAlign="center">
                <FontAwesomeIcon icon={item.icon} />
              </Box>
              {!collapsed && (
                <Text ml={2}>{item.title}</Text>
              )}
            </ChakraLink>
          );
        })}
      </VStack>
    </Box>
  );
};

// -----------------------------------------------------------
// HEADER COMPONENT
// -----------------------------------------------------------
export const HeaderNavbar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bgHeader = useColorModeValue("blue.600", "blue.900");
  const { useInfoUserAuth } = useContext(UseDataUserContext);
  const token = localStorage.getItem('USER_AUTH') || sessionStorage.getItem('USER_AUTH') || '';
  const navigate = useNavigate();

  // Notifications (including unread emails)
  const [notifications, setNotifications] = useState([]);
  // Online/offline
  const [online, setOnline] = useState(navigator.onLine);
  // Clock
  const [time, setTime] = useState(new Date());

  // Handle logout
  const handleLogout = () => {
    ApiConsume('USERS__AUTH_LOGOUT_USER', 'POST', { refresh_token: token })
      .then(() => {
        localStorage.removeItem('USER_AUTH');
        sessionStorage.removeItem('USER_AUTH');
        window.location.href = '/';
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Update clock each second
  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  // Detect online/offline
  useEffect(() => {
    const goOnline = () => setOnline(true);
    const goOffline = () => setOnline(false);
    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);
    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  // Fetch unread emails
  useEffect(() => {
    if (!token) return;
    fetchUnreadEmails();

    const interval = setInterval(() => {
      fetchUnreadEmails();
    }, 60000); // e.g. every 1 minute

    return () => clearInterval(interval);
  }, [token]);

  const fetchUnreadEmails = async () => {
    try {
      const res = await axios.get('/emails/list/inbox/?unread=1', {
        headers: { Authorization: `Bearer ${token}` }
      });
      const newEmails = res.data.map((email) => ({
        id: `email-${email.id}`,
        message: `Nuevo correo: ${email.subject}`,
        read: !!email.is_read,
        link: `/panel/Correo/email/${email.id}`,
        type: 'email'
      }));

      setNotifications((prev) => {
        const prevWithoutEmails = prev.filter((n) => n.type !== 'email');
        return [...prevWithoutEmails, ...newEmails];
      });
    } catch (error) {
      console.error("Error fetching unread emails", error);
    }
  };

  // Mark all as read
  const markAllAsRead = () => {
    setNotifications((prev) => prev.map((n) => ({ ...n, read: true })));
  };

  // On notification click
  const handleNotificationClick = (idx) => {
    const notif = notifications[idx];
    setNotifications((prev) => {
      const updated = [...prev];
      updated[idx].read = true;
      return updated;
    });
    if (notif.link) navigate(notif.link);
  };

  const unreadCount = notifications.filter((n) => !n.read).length;

  return (
    <Flex
      as="header"
      bg={bgHeader}
      color="white"
      align="center"
      justify="space-between"
      px={4}
      py={2}
      shadow="md"
    >
      {/* Left side: logo or system name */}
      <Box>
        <Text fontWeight="bold">WAM Soluciones</Text>
      </Box>

      {/* Right side: clock, weather, notifs, profile */}
      <Flex align="center">
        {/* Toggle Dark/Light */}
        <IconButton
          aria-label="Toggle color mode"
          icon={<FontAwesomeIcon icon={colorMode === 'light' ? faMoon : faSun} />}
          variant="ghost"
          colorScheme="whiteAlpha"
          mr={4}
          onClick={toggleColorMode}
        />

        {/* Online/Offline */}
        <Badge colorScheme={online ? "green" : "red"} mr={4}>
          {online ? "Online" : "Offline"}
        </Badge>

        {/* Clock */}
        <HStack spacing={2} mr={4}>
          <FontAwesomeIcon icon={faClock} />
          <Text>{time.toLocaleTimeString('en-US', { hour12: true })}</Text>
        </HStack>

        {/* Weather */}
        <HStack spacing={2} mr={4}>
          <FontAwesomeIcon icon={faCloudSun} />
          <WeatherComponent />
        </HStack>

        {/* Notifications */}
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<FontAwesomeIcon icon={faBell} />}
            variant="ghost"
            colorScheme="whiteAlpha"
            position="relative"
            mr={4}
          >
            {unreadCount > 0 && (
              <Badge
                colorScheme="red"
                position="absolute"
                top="-1"
                right="-1"
                borderRadius="50%"
                px={2}
                py={0.5}
              >
                {unreadCount}
              </Badge>
            )}
          </MenuButton>

          <MenuList bg="gray.700" color="whiteAlpha.900" borderColor="gray.600">
            <Box px={3} py={2} fontWeight="bold">
              Notificaciones
            </Box>

            {notifications.length > 0 ? (
              notifications.map((notif, idx) => (
                <MenuItem
                  key={notif.id}
                  onClick={() => handleNotificationClick(idx)}
                  _hover={{ bg: "gray.600", color: "white" }}
                  icon={<FontAwesomeIcon icon={notif.type === 'email' ? faEnvelopeOpen : faBell} />}
                >
                  {notif.message}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled _hover={{ bg: "gray.700" }}>
                Sin notificaciones
              </MenuItem>
            )}

            <MenuDivider borderColor="gray.500" />
            <MenuItem
              onClick={markAllAsRead}
              _hover={{ bg: "gray.600", color: "white" }}
            >
              Marcar todas como leídas
            </MenuItem>
          </MenuList>
        </Menu>

        {/* Profile */}
        <Menu>
          <MenuButton
            as={Button}
            variant="ghost"
            colorScheme="whiteAlpha"
            rightIcon={<FontAwesomeIcon icon={faUserCircle} />}
          >
            {useInfoUserAuth?.data
              ? `${useInfoUserAuth.data.first_name} ${useInfoUserAuth.data.last_name}`
              : "Usuario"
            }
          </MenuButton>
          <MenuList bg="gray.700" color="whiteAlpha.900" borderColor="gray.600">
            <MenuItem icon={<FontAwesomeIcon icon={faUserCircle} />}>
              Perfil
            </MenuItem>
            <MenuDivider borderColor="gray.500" />
            <MenuItem
              icon={<FontAwesomeIcon icon={faSignOutAlt} style={{ color: "red" }} />}
              onClick={handleLogout}
            >
              Cerrar sesión
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};