// src/App.js

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as CoreRouter } from 'react-router-dom';
import NotificationsSystem, { atalhoTheme, useNotifications, setUpNotifications } from 'reapop';
import 'react-month-picker/css/month-picker.css';
import 'react-tooltip/dist/react-tooltip.css';
import './_Assets/scss/volt.scss';
import './_Assets/App.css';
import './variables.css';

import { LayoutPublic, LayoutPrivate } from './Components/_UI/_LayoutsApp';

setUpNotifications({
  defaultProps: {
    position: 'top-right',
    dismissible: true,
    dismissAfter: 3000,
    allowHTML: true
  }
});

const AppRouter = lazy(() => import('./AppRouter'));

export const App = () => {
  let isLogged = false;

  if (localStorage.getItem('USER_AUTH') !== null || sessionStorage.getItem('USER_AUTH') !== null) {
    isLogged = true;
  }

  const { notifications, dismissNotification } = useNotifications();

  return (
    <CoreRouter>
      <div>
        <NotificationsSystem
          notifications={notifications}
          dismissNotification={(id) => dismissNotification(id)}
          theme={atalhoTheme}
        />
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        {!isLogged ? (
          <LayoutPublic>
            <AppRouter isLogged={isLogged} />
          </LayoutPublic>
        ) : (
          <LayoutPrivate>
            <AppRouter isLogged={isLogged} />
          </LayoutPrivate>
        )}
      </Suspense>
    </CoreRouter>
  );
};