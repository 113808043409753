// src/Components/_UI/LayoutPrivate.js

import React, { useContext } from "react";
import { Flex, Box, useColorModeValue } from "@chakra-ui/react";
import { ThemeContext } from "../../_Hooks/_Context/ThemeContext";
import { AdminSidebarApp, HeaderNavbar } from "./_NavigationApp";
import { FloatingChatBubbleChakra } from "../Private/chatia/FloatingChatBubbleChakra";

// If you want a public layout too
export const LayoutPublic = ({ children }) => {
  return <>{children}</>;
};

export const LayoutPrivate = ({ children }) => {
  // If you are using a custom ThemeContext that sets a theme = 'dark' | 'light':
  const { theme } = useContext(ThemeContext);

  // Example of a background color that changes with theme:
  const bgMain = useColorModeValue("gray.50", "gray.900");
  
  return (
    // Main container occupying full viewport height
    <Flex h="100vh">
      {/* Sidebar left, also 100vh */}
      <AdminSidebarApp />

      {/* Right section: header (top) + main content */}
      <Flex direction="column" flex="1" bg={bgMain}>
        {/* HeaderNavbar at top */}
        <HeaderNavbar />

        {/* Actual content area below header */}
        <Box p={4} flex="1" overflowY="auto">
          {children}
        </Box>

        {/* Optional floating chat bubble */}
        <FloatingChatBubbleChakra />
      </Flex>
    </Flex>
  );
};