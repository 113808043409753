// src/_Hooks/_Context/useDataUserAuthenticated.js

import { useJwt } from "react-jwt";
import { ApiConsume } from "../../_API/ApiConsume";
import { createContext, useState, useEffect } from "react";
import { useNotifications } from 'reapop';

export const UseDataUserContext = createContext();

export const UseDataUserProvider = ({ children }) => {
    let isLogged = false;
    let token = '';

    // 1) Verificamos si existe un token en localStorage o sessionStorage
    if (localStorage.getItem('USER_AUTH') !== null) {
        isLogged = true;
        token = localStorage.getItem('USER_AUTH');
    } else if (sessionStorage.getItem('USER_AUTH') !== null) {
        isLogged = true;
        token = sessionStorage.getItem('USER_AUTH');
    }

    const { notify } = useNotifications();
    // 2) Decodificamos el token para extraer datos, p. ej. user_id, is_associated
    const { decodedToken } = useJwt(token);

    // 3) Estado principal de la info del usuario logueado (o del principal)
    const [useInfoUserAuth, setInfoUserAuth] = useState({
        isLoading: true,
        data: {
            id: 0,
            first_name: "",
            last_name: "",
            domicilio: "",
            telefono: "",
            cuit: "",
            provincia: "",
            codigo_postal: "",
            afip: "",
            username: "",
            email: "",
            role: "",
            numero_inscripcion_rca_rcpa: "",
            telefono_urgencias: "",
            token: null
        }
    });

    // 4) Estado para actualizar perfil
    const [useInputProfileUserValue, setInputProfileUserValue] = useState({
        first_name: "",
        last_name: "",
        domicilio: "",
        telefono: "",
        cuit: "",
        provincia: "",
        codigo_postal: "",
        afip: "",
        username: "",
        email: "",
        role: "",
        numero_inscripcion_rca_rcpa: "",
        telefono_urgencias: ""
    });

    /**
     * Función para obtener datos del usuario principal.
     * Si el token indica que es usuario asociado => se usa main_user_id 
     * (u otro campo que tengas) en vez de user_id, 
     * para la llamada a /user/users/:id.
     */
    const fetchUserData = async () => {
        if (!isLogged || !decodedToken) {
            // No estamos logueados o no hay token decodificado
            setInfoUserAuth(prev => ({
                ...prev,
                isLoading: false
            }));
            return;
        }

        // Revisar si en el token tenemos is_associated y main_user_id
        let finalUserId = decodedToken.user_id; // por defecto
        if (decodedToken.is_associated && decodedToken.main_user_id) {
            // Sobrescribimos con ID del principal
            finalUserId = decodedToken.main_user_id;
            console.log("=> Es usuario asociado. Cargamos la data del principal con ID:", finalUserId);
        }

        if (!finalUserId) {
            // Sin user_id => nada que hacer
            setInfoUserAuth(prev => ({
                ...prev,
                isLoading: false
            }));
            return;
        }

        try {
            // Consumimos la API para /user/users/:id => USERS__GET_DETAIL_USER
            // Ajusta según el alias que hayas definido en tu "APISCONSTS" o similar
            const res = await ApiConsume(
                "USERS__GET_DETAIL_USER", 
                'GET', 
                {}, 
                finalUserId, // <-- id real a buscar
                token
            );

            if (res.msg_error) {
                throw new Error(res.msg_error);
            }

            const DATA = res.data || {};
            setInfoUserAuth({
                isLoading: false,
                data: {
                    id: DATA.id,
                    first_name: DATA.first_name || "",
                    last_name: DATA.last_name || "",
                    domicilio: DATA.domicilio || "",
                    telefono: DATA.telefono || "",
                    cuit: DATA.cuit || "",
                    provincia: DATA.provincia || "",
                    codigo_postal: DATA.codigo_postal || "",
                    afip: DATA.afip || "",
                    username: DATA.username || "",
                    email: DATA.email || "",
                    role: DATA.role || "",
                    numero_inscripcion_rca_rcpa: DATA.numero_inscripcion_rca_rcpa || "",
                    telefono_urgencias: DATA.telefono_urgencias || "",
                    token: token
                }
            });

            // Si tienes un formulario para editar perfil, lo llenas:
            setInputProfileUserValue({
                first_name: DATA.first_name || "",
                last_name: DATA.last_name || "",
                domicilio: DATA.domicilio || "",
                telefono: DATA.telefono || "",
                cuit: DATA.cuit || "",
                provincia: DATA.provincia || "",
                codigo_postal: DATA.codigo_postal || "",
                afip: DATA.afip || "",
                username: DATA.username || "",
                email: DATA.email || "",
                role: DATA.role || "",
                numero_inscripcion_rca_rcpa: DATA.numero_inscripcion_rca_rcpa || "",
                telefono_urgencias: DATA.telefono_urgencias || ""
            });

        } catch (error) {
            console.error("Error al obtener datos del usuario (o principal):", error);
            notify('Error al obtener los datos del usuario. Inténtalo nuevamente.', 'error');

            setInfoUserAuth({
                isLoading: false,
                data: {
                    id: 0,
                    first_name: "",
                    last_name: "",
                    domicilio: "",
                    telefono: "",
                    cuit: "",
                    provincia: "",
                    codigo_postal: "",
                    afip: "",
                    username: "",
                    email: "",
                    role: "",
                    numero_inscripcion_rca_rcpa: "",
                    telefono_urgencias: "",
                    token: null
                }
            });
            setInputProfileUserValue({
                first_name: "",
                last_name: "",
                domicilio: "",
                telefono: "",
                cuit: "",
                provincia: "",
                codigo_postal: "",
                afip: "",
                username: "",
                email: "",
                role: "",
                numero_inscripcion_rca_rcpa: "",
                telefono_urgencias: ""
            });
        }
    };

    // Llamamos a fetchUserData una vez que sepamos si hay token y qué dice el decodedToken
    useEffect(() => {
        fetchUserData();
        
    }, [isLogged, decodedToken, token]);

    /**
     * Ejemplo de función para actualizar perfil (usuario principal).
     * No la quites si la usas en tus forms.
     */
    const onUpdateProfileUserAuth = async (e) => {
        e.preventDefault();
        try {
            const API = await ApiConsume(
                "USERS__UPDATE_USER", 
                'PUT', 
                useInputProfileUserValue, 
                useInfoUserAuth.data.id, 
                token
            );
            if (API.msg_success) {
                notify(API.msg_success, 'success');
                await fetchUserData(); // Re-fetch para refrescar
            } else {
                notify('Error al actualizar el perfil. Inténtalo nuevamente.', 'error');
            }
        } catch (error) {
            console.error('Error al actualizar el perfil:', error);
            notify('Error al actualizar el perfil. Inténtalo nuevamente.', 'error');
        }
    };

    return (
        <UseDataUserContext.Provider value={{
            decodedToken,
            useInfoUserAuth,
            setInfoUserAuth,
            useInputProfileUserValue,
            setInputProfileUserValue,
            onUpdateProfileUserAuth
        }}>
            {children}
        </UseDataUserContext.Provider>
    );
};