// src/Components/Private/chatia/FloatingChatBubbleChakra.jsx

import React from "react";
import {
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure
} from "@chakra-ui/react";
import { ChatIcon } from "@chakra-ui/icons";

export const FloatingChatBubbleChakra = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {/* Botón flotante para abrir el modal */}
      <IconButton
        aria-label="Open chat"
        icon={<ChatIcon />}
        colorScheme="blue"
        onClick={onOpen}
        position="fixed"
        bottom="20px"
        right="20px"
        borderRadius="50%"
        zIndex={9999}
        w="60px"
        h="60px"
      />

      {/* Modal con el iframe de Chatbase */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Asistente Virtual</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <iframe
              src="https://www.chatbase.co/chatbot-iframe/gbXXHgkCBJfJx0Fq3cjdx"
              width="100%"
              style={{ height: "400px", minHeight: "400px" }}
              frameBorder="0"
              title="Chatbase Bot"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};